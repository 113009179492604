import Navbar from "../layout/navbar";
import axiosClient from "../../../../api/axios";
import { useEffect, useState } from "react";
import AddElectionModal from "./addElection";

const ElectionType = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const fetchData = async () => {
    // Fetch data logic, replace with your actual fetch logic
    try {
      setLoading(true);
      await axiosClient
        .get("/categories")
        .then((res) => {
          setRecords(res.data);
          setLoading(false);
        })
        .catch((error) => {

        });
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        {/* Vertical Navbar */}
        <Navbar />
        {/* Main content */}
        <div className="h-screen flex-grow-1 overflow-y-lg-auto">
          {/* Header */}
          <header className="bg-surface-primary border-bottom pt-6">
            <div className="container-fluid">
              <div className="mb-npx">
                <div className="row align-items-center">
                  <div className="col-sm-6 col-12 mb-4 mb-sm-0">
                    {/* Title */}
                    <h1 className="h2 mb-0 ls-tight">
                      Election Management System
                    </h1>
                  </div>
                  {/* Actions */}
                  <div className="col-sm-6 col-12 text-sm-end">
                    <div className="mx-n1">
                      <button
                        onClick={openModal}
                        className="btn d-inline-flex btn-sm btn-primary mx-1"
                      >
                        <span className=" pe-2">
                          <i className="bi bi-plus" />
                        </span>
                        <span>Create</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* Nav */}
                <ul className="nav nav-tabs mt-4 overflow-x border-0">
                  <li className="nav-item ">
                    <a href="#" className="nav-link active">
                      All files
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>
          {/* Main */}
          <main className="py-6 bg-surface-secondary">
            <div className="container-fluid">
              <div className="card shadow border-0 mb-7">
                <div className="card-header">
                  <h5 className="mb-0">Election Type </h5>
                </div>
                <div className="table-responsive">
                  <table className="table table-hover table-nowrap">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Election Type</th>
                        <th scope="col">Status</th>
                        <th scope="col">Start Date/Time</th>
                        <th scope="col">End Date/Time</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {records &&
                        records.categories &&
                        records.categories.map((category) => (
                          <tr key={category.id}>
                            <td>
                              <img
                                alt="..."
                                src="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                                className="avatar avatar-sm rounded-circle me-2"
                              />
                              <a
                                className="text-heading font-semibold"
                                href="#"
                              >
                                {category.name}
                              </a>
                            </td>
                            <td>
                            <span className={`badge badge-lg badge-dot ${category.status === 0 ? 'bg-primary' : category.status === 1 ? 'bg-success' :'bg-danger'}`}>
                                {category.status === 0 ? 'Scheduled' : category.status === 1 ? 'Started' :'Ended'}
                            </span>
                            </td>
                            <td>{category.start_time}</td>
                            <td>{category.end_time}</td>
                            <td>
                              <span className="badge badge-lg badge-dot">
                                <i className="bg-success" />
                              </span>
                            </td>
                            <td className="text-end">
                              <a
                                href="#"
                                className="btn btn-sm btn-neutral"
                              ></a>
                              <button
                                type="button"
                                className="btn btn-sm btn-square btn-neutral text-danger-hover"
                              >
                                <i className="bi bi-trash" />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <AddElectionModal
        showModal={showModal}
        closeModal={closeModal}
        fetchData={fetchData}
      />
    </>
  );
};
export default ElectionType;
