import axios from 'axios';

export const API_URL = "https://nauth-unizik.ng/elect";
const axiosClient = axios.create({
  baseURL: `${API_URL}/api`, // Replace with your API base URL
  timeout: 10000, // Set your desired timeout
  transformRequest: [(data, headers) => {
    try {
      // Ensure Content-Type is set for JSON
      headers['Content-Type'] = 'application/json';
      return JSON.stringify(data); // Force JSON.stringify for all request data
    } catch (error) {
      console.error("Error stringifying request data:", error);
      return data; // Return raw data if JSON.stringify fails
    }
  }],
  transformResponse: [(data) => {
    try {
      return JSON.parse(data); // Force JSON parsing for all response data
    } catch (error) {
      console.error("Error parsing response JSON:", error);
      return data; // Return raw data if JSON parsing fails
    }
  }],
});

// Add request interceptor
axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Add response interceptor
axiosClient.interceptors.response.use(
  (response) => {
    console.log("Response Interceptor:", response);
    if (response.data.message === "Unauthenticated.") {
      localStorage.removeItem('token');
      window.location.href = '/';
    }
    return response;
  },
  (error) => {
    const { response } = error;
    console.log("Error Interceptor:", response);

    if (response && [401, 403, 404].includes(response.status)) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }
    throw error;
  }
);

export default axiosClient;


// axiosConfig.js
// import axios from 'axios';

// export const API_URL = "https://nauth-unizik.ng/elect";

// const axiosClient = axios.create({
//   baseURL: `${API_URL}/api`,// replace with your API base URL
//   timeout: 10000, // set your desired timeout
//   // other axios configurations can be added here
// });

// axiosClient.interceptors.request.use((config) => {
//    const token = localStorage.getItem('token');
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// })
// axiosClient.interceptors.response.use(
//   (response) => {
//     console.log("Response Interceptor:", response);
//     if (response.data.message === "Unauthenticated.") {
//       localStorage.removeItem('token');
//       window.location.href = '/';
//     }
//     return response;
//   },
//   (error) => {
//     const { response } = error;
//     console.log("Error Interceptor:", response);

//     if (response && response.status === 401) {
//       localStorage.removeItem('token');
//       window.location.href = '/';
//     } else if (response && response.status === 404) {
//       localStorage.removeItem('token');
//       window.location.href = '/';
//     } else if (response && response.status === 403) {
//       localStorage.removeItem('token');
//       window.location.href = '/';
//     }
//     throw error;
//   }
// );

// export default axiosClient;

